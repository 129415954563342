@media  (min-width: 768px) and(max-width: 1280px) {
    .fheight{
        height: 140px;
    }
}

@media (min-width: 451px) and  (max-width: 767px) {
    .fheight{
        height: 100px;
    }
}

@media (max-width: 450px) {
    .fheight{
        height: 100px;
    }
}